import React, { FC, useContext } from "react";
import { graphql } from "gatsby";
import { EventQuery, BlockFragment } from "../../graphql-types";
import { Blocks } from "../components/blocks";
import { colors, spaceRem, targetBaseFontSizePx } from "../global-style";
import { LayoutContext } from "../components/layout";
import { css } from "styled-components";
import BackgroundImage, { IFluidObject } from "gatsby-background-image";
import { useLocation } from "@reach/router";

export const query = graphql`
  query Event($id: String) {
    header: file(relativePath: { eq: "page-template-header.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    wpEvent(id: { eq: $id }) {
      title
      blocks {
        ...Block
      }
      eventCustomFields {
        eventType
        eventDate
        eventLocation
        eventPeriod {
          eventPeriodEndDate
          eventPeriodStartDate
        }
      }
    }

    allWpEvent {
      edges {
        node {
          id
          title
          dateGmt
          language {
            slug
          }
        }
      }
    }
  }
`;

const Page: FC<{ data: EventQuery }> = props => {
  const { remInPx } = useContext(LayoutContext);

  const location = useLocation();
  const currentLanguage = location.pathname.split("/").filter(v => v)[0] as
    | "nl"
    | "en";

  return (
    <>
      <BackgroundImage
        style={{
          position: "relative",
          height: "30vw",
          minHeight: "40vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        fluid={props.data.header?.childImageSharp?.fluid as IFluidObject}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              hyphens: "none",
              maxWidth: "30ch",
              textAlign: "center",
              color: colors.white,
              paddingLeft: `${0.5 * spaceRem}rem`,
              paddingRight: `${0.5 * spaceRem}rem`,
              marginBottom: `${0.5 * spaceRem}rem`,
            }}
            css={(() => {
              const minFontSizeRem = 1.25;
              const maxFontSizeRem = 3;
              const vwRangeStartPx = 500;

              return css`
                font-size: ${(minFontSizeRem *
                  (remInPx || targetBaseFontSizePx)) /
                  (vwRangeStartPx / 100)}vw;

                @media (max-width: ${vwRangeStartPx}px) {
                  font-size: ${minFontSizeRem}rem;
                }

                @media (min-width: ${vwRangeStartPx *
                    (maxFontSizeRem / minFontSizeRem)}px) {
                  font-size: ${maxFontSizeRem}rem;
                }
              `;
            })()}
            dangerouslySetInnerHTML={{
              __html: props.data.wpEvent?.title || "",
            }}
          />

          <h3 style={{ color: colors.white }}>
            {new Date(
              (props.data.wpEvent?.eventCustomFields?.eventType ===
                "single_date" &&
                props.data.wpEvent?.eventCustomFields.eventDate?.replace(
                  /(\d{2})\/(\d{2})\/(\d{4})/,
                  "$2/$1/$3"
                )) ||
                props.data.wpEvent?.eventCustomFields?.eventPeriod?.eventPeriodStartDate?.replace(
                  /(\d{2})\/(\d{2})\/(\d{4})/,
                  "$2/$1/$3"
                ) ||
                ""
            ).toLocaleDateString(currentLanguage, {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
            {props.data.wpEvent?.eventCustomFields?.eventType === "period"
              ? `– ${new Date(
                  props.data.wpEvent?.eventCustomFields?.eventPeriod?.eventPeriodEndDate?.replace(
                    /(\d{2})\/(\d{2})\/(\d{4})/,
                    "$2/$1/$3"
                  ) || ""
                ).toLocaleDateString(currentLanguage, {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}`
              : null}{" "}
            — {props.data.wpEvent?.eventCustomFields?.eventLocation}
          </h3>
        </div>

        <svg
          viewBox="0 0 1918 128"
          style={{ width: "100%", position: "absolute", bottom: 0 }}
        >
          <defs>
            <path id="page_template_header_wave__a" d="M0 0h1918v325H0z" />
            <path id="page_template_header_wave__c" d="M0 0h1918v432H0z" />
          </defs>
          <g transform="translate(0 -26)" fill="none" fillRule="evenodd">
            <mask id="page_template_header_wave__b" fill="#fff">
              <use xlinkHref="#page_template_header_wave__a" />
            </mask>
            <g mask="url(#page_template_header_wave__b)">
              <g transform="rotate(180 959 231)">
                <mask id="page_template_header_wave__d" fill="#fff">
                  <use xlinkHref="#page_template_header_wave__c" />
                </mask>
                <g mask="url(#page_template_header_wave__d)">
                  <path
                    d="M1938.649 336.733C1282.579 230.378 647.83 504.607-8.004 402.473L-41.777-6.066C614.295 100.29 1249.044-173.94 1904.876-71.81l33.773 408.542z"
                    fill="#FFF"
                    fillRule="nonzero"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </BackgroundImage>

      <div>
        <Blocks
          blocks={props.data.wpEvent?.blocks as BlockFragment[]}
          allWide={false}
        />
      </div>

      {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
    </>
  );
};

export default Page;
